import { getDateTimeGreenwich } from './getDateTimeGreenwich';
import { getTimeStamp } from './getTimeStamp';
import { v4 as uuidv4 } from 'uuid';
export const getGaParams = () => {
    var _a;
    return {
        event_option1: 'mitgo widget',
        event_option2: 'undefined',
        event_option3: 'undefined',
        event_option4: 'undefined',
        hit_id: getTimeStamp(),
        uu_id: uuidv4(),
        uu_id_2: uuidv4(),
        datetime_greenwich: getDateTimeGreenwich(),
        locale: 'en',
        program_id: 'undefined',
        advertiser_id: 'undefined',
        advcampaign_id: 'undefined',
        publisher_id: 'undefined',
        keycloak_id: 'undefined',
        website_id: 'undefined',
        page_params: 'undefined',
        page_location: (_a = window.location.href) !== null && _a !== void 0 ? _a : 'undefined',
        user_id: undefined,
    };
};
