import ar from './ar/translation.json';
import cs from './cs/translation.json';
import de from './de/translation.json';
import en from './en/translation.json';
import es from './es/translation.json';
import fr from './fr/translation.json';
import it from './it/translation.json';
import pl from './pl/translation.json';
import pt from './pt/translation.json';
import ru from './ru/translation.json';
import uk from './uk/translation.json';
export const LOCALES = {
    en,
    ru,
    de,
    pt,
    pl,
    es,
    uk,
    ua: uk,
    it,
    fr,
    ar,
    cs,
};
export const SUPPORTED_LANGUAGES = Object.keys(LOCALES);
