// Shared Event Dispatcher (used for communication lit siblings components and widget instance)
class EventDispatcher {
    constructor() {
        this.listeners = {};
    }
    addEventListener(event, listener) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push(listener);
    }
    removeEventListener(event, listener) {
        if (this.listeners[event]) {
            this.listeners[event] = this.listeners[event].filter((l) => l !== listener);
        }
    }
    dispatchEvent(event, detail) {
        if (this.listeners[event]) {
            this.listeners[event].forEach((listener) => listener(detail));
        }
    }
    removeAllEventListeners() {
        this.listeners = {};
    }
}
export const eventDispatcher = new EventDispatcher();
