import { PRODUCTS, PRODUCTS_BY_REGION, WINDOW_WIDGET_NAME } from '../constants';
export const getFilteredProducts = (language) => {
    var _a;
    if (!language) {
        language = window[WINDOW_WIDGET_NAME].config.language;
    }
    if (!language) {
        return PRODUCTS;
    }
    const products = (_a = PRODUCTS_BY_REGION[language]) !== null && _a !== void 0 ? _a : PRODUCTS_BY_REGION.en;
    return PRODUCTS.map((group) => (Object.assign(Object.assign({}, group), { products: group.products
            .filter(({ name, link }) => {
            const currentDomain = `${window.location.protocol}//${window.location.hostname.toLowerCase()}`;
            return products.includes(name) && !link.toLowerCase().includes(currentDomain);
        }) })))
        .filter(({ products }) => products.length > 0);
};
