export const getCookie = (name) => {
    const matches = document.cookie.match(new RegExp(`(?:^|; )${name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1')}=([^;]*)`));
    return matches ? decodeURIComponent(matches[1]) : null;
};
export const getGaClientid = () => {
    let match = document.cookie.match('(?:^|;)\\s*_ga=([^;]*)');
    const raw = (match) ? decodeURIComponent(match[1]) : null;
    if (raw) {
        match = raw.match(/(\d+\.\d+)$/);
    }
    const gacid = (match) ? match[1] : null;
    if (gacid) {
        return gacid;
    }
};
