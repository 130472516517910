import { APP_INIT_STATUSES, DEFAULT_CONFIG, TOGGLE_WIDGET_BUTTON_BOTTOM, TOGGLE_WIDGET_BUTTON_LEFT, TOGGLE_WIDGET_TAG, WIDGET_ROOT_ID, WIDGET_TAG, WINDOW_WIDGET_NAME, PRODUCTS_BY_REGION, } from './constants';
import './components/Widget';
import './components/ToggleWidgetButton';
import { eventDispatcher } from './eventDispatcher';
import { SUPPORTED_LANGUAGES } from './locales';
import { getCookie, getGaClientid } from './utils/analytics/getCookie';
import { getPageTitle } from './utils/analytics/getActiveTabUrl';
import { sendGAEvent } from './utils/sendGAEvent';
export class MitgoWidget {
    constructor(data) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        this.widgetElement = null;
        this.toggleButtonElement = null;
        const clientId = (_a = getGaClientid()) !== null && _a !== void 0 ? _a : null;
        const languageTagValue = (_b = document.querySelector('meta[property="og:locale"]')) === null || _b === void 0 ? void 0 : _b.getAttribute('content');
        const navigatorLanguage = navigator.language.slice(0, 2).toLowerCase();
        window[WINDOW_WIDGET_NAME].config = Object.assign(Object.assign(Object.assign({}, DEFAULT_CONFIG), data), { language: (_e = (_d = (_c = data.language) !== null && _c !== void 0 ? _c : data.lang) !== null && _d !== void 0 ? _d : languageTagValue) !== null && _e !== void 0 ? _e : navigatorLanguage, pageGaParams: {
                client_id: clientId !== null && clientId !== void 0 ? clientId : 'undefined',
                session_id: (_g = (_f = getCookie('_ga_32HHLCD33P')) === null || _f === void 0 ? void 0 : _f.split('.')[2]) !== null && _g !== void 0 ? _g : 'undefined',
                page_title: getPageTitle(),
                url_tags: (_h = window.location.search) !== null && _h !== void 0 ? _h : 'undefined',
                client_id_ga: clientId ? `${clientId}.` : undefined,
            } });
    }
    init() {
        return new Promise((resolve) => {
            const { language } = window[WINDOW_WIDGET_NAME].config;
            if (!window[WINDOW_WIDGET_NAME].alreadyInitialized) {
                sendGAEvent('initialization_mitgo_widget', {
                    event_option2: 'widget initialization',
                });
            }
            if (PRODUCTS_BY_REGION[language] && PRODUCTS_BY_REGION[language].length === 0) {
                // This language doesn't have any products (empty array, not default value)
                // Just return, but don't throw an error
                return resolve();
            }
            try {
                this.destroy();
                const config = window[WINDOW_WIDGET_NAME].config;
                // Insert web components
                const html = `
    <${WIDGET_TAG} language="${config.language}" position="${config.position}"></${WIDGET_TAG}>
    <${TOGGLE_WIDGET_TAG} class="mitgo-widget-toggle-button" style="position: fixed; left: ${TOGGLE_WIDGET_BUTTON_LEFT}px; bottom: ${TOGGLE_WIDGET_BUTTON_BOTTOM}px; z-index: 999999;"></${TOGGLE_WIDGET_TAG}>
  `;
                const root = document.createElement('div');
                root.id = WIDGET_ROOT_ID;
                root.innerHTML = html;
                document.body.append(root);
                // Save web components dom nodes for change attributes
                this.widgetElement = document.querySelector(WIDGET_TAG);
                this.toggleButtonElement = document.querySelector(TOGGLE_WIDGET_TAG);
                window[WINDOW_WIDGET_NAME].status = APP_INIT_STATUSES.INIT_SUCCESS;
                if (!window[WINDOW_WIDGET_NAME].alreadyInitialized) {
                    sendGAEvent('show_mitgo_widget', {
                        event_option2: 'widget showing',
                    });
                }
                resolve();
                window[WINDOW_WIDGET_NAME].alreadyInitialized = true;
            }
            catch (e) {
                console.error(e);
                window[WINDOW_WIDGET_NAME].status = APP_INIT_STATUSES.INIT_FAILED;
                window[WINDOW_WIDGET_NAME].alreadyInitialized = false;
            }
        });
    }
    destroy() {
        var _a;
        eventDispatcher.removeAllEventListeners();
        (_a = document.getElementById(WIDGET_ROOT_ID)) === null || _a === void 0 ? void 0 : _a.remove();
        window[WINDOW_WIDGET_NAME].status = APP_INIT_STATUSES.DESTROYED;
    }
    setLanguage(language) {
        const supportedLanguages = this.getSupportedLanguages();
        if (!language) {
            console.error('The language you selected is not defined! Plase available languages at check method "getSupportedLanguages".');
            return;
        }
        if (!supportedLanguages) {
            console.error('The languages is not supported');
            return;
        }
        if (!supportedLanguages.includes(language)) {
            console.error('The language you selected is not supported!');
            return;
        }
        window[WINDOW_WIDGET_NAME].config.language = language;
        this.destroy();
        this.init();
    }
    getCurrentLanguage() {
        return window[WINDOW_WIDGET_NAME].config.language;
    }
    getSupportedLanguages() {
        return SUPPORTED_LANGUAGES;
    }
    setPosition(position) {
        var _a;
        if (!position || typeof position !== 'string') {
            console.error('The "position" is not defined!');
            return;
        }
        if (!['left', 'right'].includes(position)) {
            console.error('The position you selected is not supported!');
            return;
        }
        window[WINDOW_WIDGET_NAME].config.position = position;
        (_a = this.widgetElement) === null || _a === void 0 ? void 0 : _a.setAttribute('position', position);
    }
}
window.MitgoWidget = MitgoWidget;
