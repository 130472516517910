export const getActiveTabUrl = () => {
    const tab = window.location.href;
    let url;
    try {
        if (!tab) {
            throw new Error('Tab url is not defined!');
        }
        url = new URL(tab);
        const params = url.searchParams;
        return params.toString();
    }
    catch (error) {
        return undefined;
    }
};
export const getPageTitle = () => {
    return document.title;
};
