export const getDateTimeGreenwich = () => {
    const date = new Date();
    // returns UTC (=GMT) Hour of the date
    const hour = date.getUTCHours();
    // returns UTC (=GMT) Minutes of the date
    const minute = date.getUTCMinutes();
    // returns UTC (=GMT) Seconds of the date
    const seconds = date.getUTCSeconds();
    // returns UTC (=GMT) year of the date
    const year = date.getUTCFullYear();
    // returns UTC (=GMT) month (0-11)
    //    0 is January, 11 is December
    const month = date.getUTCMonth();
    // returns UTC (=GMT) day of the month (1-31)
    const day = date.getUTCDate();
    return `${year}-${month + 1}-${day} ${hour}:${minute}:${seconds}`;
};
