var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { IS_OPENED_ON_DESKTOP_LOCALSTORAGE_KEY, HIDE_WIDGET_EVENT, MOBILE_MAX_WIDTH, SHOW_WIDGET_EVENT, TOGGLE_WIDGET_TAG, } from './../../constants';
import { css, html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import clsx from 'clsx';
import { eventDispatcher } from '../../eventDispatcher';
import { sendGAEvent } from '../../utils/sendGAEvent';
import commonIcon from '../../assets/toggle.svg';
import { getFilteredProducts } from '../../utils/getFilteredProducts';
let ToggleWidgetButton = class ToggleWidgetButton extends LitElement {
    constructor() {
        super(...arguments);
        this.visible = true;
        this.prevActiveIcon = 0;
        this.activeIcon = 0;
        // Used for animation by transition (additional class after active)
        this.animationInterval = undefined;
        this.icons = [];
    }
    connectedCallback() {
        super.connectedCallback();
        if (localStorage.getItem(IS_OPENED_ON_DESKTOP_LOCALSTORAGE_KEY) !== 'false' && window.innerWidth >= MOBILE_MAX_WIDTH) {
            // Automatically hide toggle button if not mobile and local storage value is not false
            this.visible = false;
        }
        // Call show toggle button when event HIDE_WIDGET_EVENT is dispatched
        eventDispatcher.addEventListener(HIDE_WIDGET_EVENT, () => this.show());
        this.icons = getFilteredProducts().flatMap(({ products }) => products.map(({ iconSvg }) => iconSvg));
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    setActiveSlide(index) {
        this.prevActiveIcon = this.activeIcon;
        this.activeIcon = index;
    }
    handleMouseOver(event) {
        if (window.matchMedia('(hover: none)').matches || this.animationInterval) {
            return;
        }
        this.setActiveSlide(1);
        this.animationInterval = setInterval(() => {
            let nextActiveIcon = this.activeIcon + 1;
            if (nextActiveIcon > this.icons.length) {
                nextActiveIcon = 0;
            }
            this.setActiveSlide(nextActiveIcon);
        }, 500);
    }
    handleMouseLeave() {
        if (window.innerWidth < MOBILE_MAX_WIDTH) {
            return;
        }
        // Clear animation interval
        clearInterval(this.animationInterval);
        this.animationInterval = undefined;
        this.activeIcon = 0;
        this.prevActiveIcon = 0;
    }
    hide() {
        // Hide toggle button
        this.visible = false;
        // Dispatch event for show widget
        eventDispatcher.dispatchEvent(SHOW_WIDGET_EVENT);
        // Send GA event and save in local storage
        localStorage.setItem(IS_OPENED_ON_DESKTOP_LOCALSTORAGE_KEY, 'true');
        sendGAEvent('click_open_mitgo_widget', {
            event_option2: 'widget hidden',
        });
        clearInterval(this.animationInterval);
        this.animationInterval = undefined;
        this.setActiveSlide(0);
    }
    show() {
        // Show toggle button
        this.visible = true;
    }
    render() {
        return html `<div class="${clsx('toggle-button', this.visible && 'toggle-button_visible')}" @click="${this.visible ? this.hide : this.show}" @mouseenter="${this.handleMouseOver}" @mouseleave="${this.handleMouseLeave}"><div class="${clsx('icon icon_common', {
            icon_active: this.activeIcon === 0,
            icon_prevActive: this.prevActiveIcon === 0,
        })}" .innerHTML="${commonIcon}"></div>${this.icons.map((icon, index) => html `<div class="${clsx('toggle-button__product icon', {
            icon_active: this.activeIcon === index + 1,
            icon_prevActive: this.prevActiveIcon === index + 1,
        })}" .innerHTML="${icon}"></div>`)}</div>`;
    }
};
ToggleWidgetButton.styles = css `.toggle-button,.toggle-button *{box-sizing:border-box}.toggle-button{width:44px;height:44px;border-radius:12px;cursor:pointer;display:flex;align-content:center;justify-content:center;align-items:center;flex-direction:row;display:none;pointer-events:none;position:relative;padding:0}.toggle-button svg{border-radius:12px;overflow:hidden}.icon{width:44px;height:44px;position:absolute;left:0;top:0;right:0;bottom:0;z-index:1;transition:opacity .35s ease-in-out;opacity:0;overflow:hidden}.icon_prevActive{z-index:2;transition:all .4s ease-in-out;opacity:1}.icon_active{z-index:3;opacity:1}.toggle-button_visible{display:flex;pointer-events:auto}@media (min-width:768px){.toggle-button{cursor:pointer;align-content:center;justify-content:center;align-items:center;flex-direction:row;display:none}.toggle-button_visible{display:flex}}`;
__decorate([
    state()
], ToggleWidgetButton.prototype, "visible", void 0);
__decorate([
    state()
], ToggleWidgetButton.prototype, "prevActiveIcon", void 0);
__decorate([
    state()
], ToggleWidgetButton.prototype, "activeIcon", void 0);
__decorate([
    state()
], ToggleWidgetButton.prototype, "animationInterval", void 0);
__decorate([
    state()
], ToggleWidgetButton.prototype, "icons", void 0);
ToggleWidgetButton = __decorate([
    customElement(TOGGLE_WIDGET_TAG)
], ToggleWidgetButton);
