import { Product } from './types';
import admitadIcon from './assets/ad-product_icon.svg';
import admitadFullIcon from './assets/ad_logo.svg';
import takeadsIcon from './assets/ta-product_icon.svg';
import takeadsFullIcon from './assets/ta_logo.svg';
import lineadsIcon from './assets/la-product_icon.svg';
import lineadsFullIcon from './assets/la_logo.svg';
import takefluenceIcon from './assets/tf-product_icon.svg';
import takefluenceFullIcon from './assets/tf_logo.svg';
import convertSocialIcon from './assets/cs-product_icon.svg';
import convertSocialFullIcon from './assets/cs_logo.svg';
import cashliIcon from './assets/cashli-product_icon.svg';
import cashliFullIcon from './assets/cashli_logo.svg';
import mobmioIcon from './assets/mobmio-product_icon.svg';
import mobmioFullIcon from './assets/mobmio_logo.svg';
import capyfastIcon from './assets/cf-product_icon.svg';
import capyfastFullIcon from './assets/cf_logo.svg';
import takprodamIcon from './assets/takprodam-product_icon.svg';
import takprodamFullIcon from './assets/takprodam_logo.svg';
import getuniqIcon from './assets/getuniq-product_icon.svg';
import getuniqFullIcon from './assets/getuniq_logo.svg';
export const PRODUCTS = [
    {
        translationKey: 'components.slides.publishers.t0',
        products: [
            {
                name: Product.admitad,
                iconSvg: admitadIcon,
                infoIconSvg: admitadFullIcon,
                link: 'https://store.admitad.com/sso/login/?role=webmaster',
                linkText: 'admitad.com',
            },
            {
                name: Product.takeads,
                iconSvg: takeadsIcon,
                infoIconSvg: takeadsFullIcon,
                link: 'https://account.takeads.com',
                linkText: 'takeads.com',
            },
            {
                name: Product.lineads,
                iconSvg: lineadsIcon,
                infoIconSvg: lineadsFullIcon,
                link: 'https://app.lineads.ai',
                linkText: 'lineads.ai',
            },
            {
                name: Product.cashli,
                iconSvg: cashliIcon,
                infoIconSvg: cashliFullIcon,
                link: 'https://cashli.in/admitad?utm_source=widget&utm_medium=partnership&utm_campaign=mitgo_widget',
                linkText: 'cashli.in',
            },
            {
                name: Product.mobmio,
                iconSvg: mobmioIcon,
                infoIconSvg: mobmioFullIcon,
                link: 'https://app.mobmio.com',
                linkText: 'mobmio.com',
            },
            {
                name: Product.getuniq,
                iconSvg: getuniqIcon,
                infoIconSvg: getuniqFullIcon,
                link: 'https://getuniq.me',
                linkText: 'getuniq.me',
            },
        ],
    },
    {
        translationKey: 'components.slides.influencers.t0',
        products: [
            {
                name: Product.takefluence,
                iconSvg: takefluenceIcon,
                infoIconSvg: takefluenceFullIcon,
                link: 'https://app.takefluence.com',
                linkText: 'takefluence.com',
            },
            {
                name: Product.convertsocial,
                iconSvg: convertSocialIcon,
                infoIconSvg: convertSocialFullIcon,
                link: 'https://account.convertsocial.net',
                linkText: 'convertsocial.net',
            },
            {
                name: Product.takprodam,
                iconSvg: takprodamIcon,
                infoIconSvg: takprodamFullIcon,
                link: 'https://app.takprodam.ru',
                linkText: 'takprodam.ru',
            },
        ],
    },
    {
        translationKey: 'components.slides.payments.t0',
        products: [
            {
                name: Product.capyfast,
                iconSvg: capyfastIcon,
                infoIconSvg: capyfastFullIcon,
                link: 'https://app.capyfast.com',
                linkText: 'capyfast.com',
            },
        ],
    },
];
export const PRODUCTS_BY_REGION = {
    en: [
        Product.admitad,
        Product.takeads,
        Product.convertsocial,
        Product.takefluence,
        Product.lineads,
        Product.mobmio,
        Product.cashli,
        Product.capyfast,
    ],
    de: [Product.admitad, Product.takeads, Product.convertsocial, Product.lineads, Product.capyfast],
    pl: [Product.admitad, Product.takeads, Product.lineads, Product.capyfast],
    ar: [Product.admitad, Product.takeads, Product.takefluence, Product.lineads, Product.mobmio],
    it: [Product.admitad, Product.takeads, Product.lineads, Product.capyfast],
    fr: [Product.admitad, Product.takeads, Product.convertsocial, Product.lineads, Product.capyfast],
    es: [Product.admitad, Product.takeads, Product.convertsocial, Product.lineads, Product.capyfast],
    pt: [Product.admitad, Product.lineads, Product.capyfast],
    uk: [Product.admitad, Product.capyfast],
    ua: [Product.admitad, Product.capyfast],
    ru: [Product.admitad, Product.takprodam, Product.getuniq],
};
export const MOVE_OVER_EDGE_TIMEOUT = 3000;
export const DEFAULT_CONFIG = {
    autoHide: true,
    position: 'left',
    language: 'en',
    business: 'undefined',
    keycloakId: 'undefined',
    userId: 'undefined',
};
export var APP_INIT_STATUSES;
(function (APP_INIT_STATUSES) {
    APP_INIT_STATUSES["INIT_INPROGRESS"] = "INIT_INPROGRESS";
    APP_INIT_STATUSES["INIT_SUCCESS"] = "INIT_SUCCESS";
    APP_INIT_STATUSES["INIT_FAILED"] = "INIT_FAILED";
    APP_INIT_STATUSES["DESTROYED"] = "DESTROYED";
})(APP_INIT_STATUSES || (APP_INIT_STATUSES = {}));
export const WINDOW_WIDGET_NAME = 'MitgoWidget';
export const WIDGET_ROOT_ID = 'mitgo-widget-root';
export const WIDGET_TAG = 'mitgo-widget';
export const SHOW_WIDGET_EVENT = 'show-widget';
export const HIDE_WIDGET_EVENT = 'hide-widget';
export const TOGGLE_WIDGET_TAG = 'mitgo-widget-toggle-button';
export const TOGGLE_WIDGET_BUTTON_LEFT = 50;
export const TOGGLE_WIDGET_BUTTON_BOTTOM = 50;
export const IS_OPENED_ON_DESKTOP_LOCALSTORAGE_KEY = 'isOpenedMitgoWidget';
export const MOBILE_MAX_WIDTH = 768;
