import { LOCALES } from '../locales';
import { WINDOW_WIDGET_NAME } from '../constants';
export const getTranslation = (key) => {
    var _a;
    const language = (_a = window[WINDOW_WIDGET_NAME].config.language) !== null && _a !== void 0 ? _a : 'en';
    const keys = key.split('.');
    const findTranslation = (obj, keys) => {
        if (keys.length === 0) {
            return typeof obj === 'string' ? obj : '';
        }
        const currentKey = keys[0];
        const remainingKeys = keys.slice(1);
        if (obj && typeof obj === 'object' && currentKey in obj) {
            return findTranslation(obj[currentKey], remainingKeys);
        }
        return '';
    };
    const text = findTranslation(LOCALES[language], keys);
    if (text) {
        return text;
    }
    else if (language !== 'en') {
        // fallback to english
        return findTranslation(LOCALES.en, keys);
    }
    return '';
};
