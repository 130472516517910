var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { getGaParams } from './analytics/getGaParams';
import { WINDOW_WIDGET_NAME } from '../constants';
const GA_ENDPOINT = 'https://www.google-analytics.com/mp/collect';
// const GA_DEBUG_ENDPOINT = 'https://www.google-analytics.com/debug/mp/collect';
export const sendGAEvent = (name, params = {}) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const { clientId, keycloakId, userId, business, language, pageGaParams } = (_a = window[WINDOW_WIDGET_NAME].config) !== null && _a !== void 0 ? _a : {};
    const gaParams = getGaParams();
    const ga4Params = Object.assign(Object.assign(Object.assign(Object.assign({}, gaParams), pageGaParams), { keycloak_id: keycloakId, user_id: userId, locale: language, event_option3: business }), params);
    const { client_id } = ga4Params, externalParams = __rest(ga4Params, ["client_id"]);
    const bodyData = {
        client_id,
        user_id: userId,
        non_personalized_ads: false,
        events: [
            {
                name,
                params: externalParams,
            },
        ],
    };
    if (process.env.NODE_ENV === 'development') {
        console.log('Send GA event: ', bodyData);
        return;
    }
    try {
        yield fetch(`${GA_ENDPOINT}?measurement_id=${process.env.APP_GOOGLE_ANALYTICS_ID}&api_secret=${process.env.APP_GOOGLE_ANALYTICS_SECRET}`, {
            method: 'POST',
            body: JSON.stringify(bodyData),
        });
    }
    catch (e) {
        console.error('Google Analytics request failed with an exception', e);
    }
});
