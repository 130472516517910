export var Product;
(function (Product) {
    Product["takprodam"] = "TakProdam";
    Product["takefluence"] = "Takefluence";
    Product["convertsocial"] = "ConvertSocial";
    Product["capyfast"] = "Capyfast";
    Product["admitad"] = "Admitad";
    Product["takeads"] = "Takeads";
    Product["lineads"] = "Lineads";
    Product["cashli"] = "Cashli";
    Product["mobmio"] = "Mobmio";
    Product["getuniq"] = "GetUniq";
})(Product || (Product = {}));
